import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PostBlock from "./postblock";

const ReadMore = ({ id }: { id: String }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPost {
        totalCount
        nodes {
          authors {
            author {
              slug {
                current
              }
              name
            }
          }
          categories {
            title
            slug {
              current
            }
          }
          slug {
            current
          }
          title
          publishedAt(formatString: "DD.MM.YYYY HH:mm")
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          _id
          _rawExcerpt
        }
      }
    }
  `);
  const Posts = data.allSanityPost.nodes;
  let count = 0;
  return (
    <ul className="flex gap-4 flex-wrap justify-center items-center py-4">
      {Posts.filter((node: any) => node._id != id).map((node: any) => {
        if (count < 2) {
          count++;
          return (
            <li className="flex-grow basis-[50rem]">
              <PostBlock node={node}></PostBlock>
            </li>
          );
        } else {
          return <></>;
        }
      })}
    </ul>
  );
};

export default ReadMore;

import * as React from "react";
import Layout from "../components/layout";
import Footer from "../components/footer";
import ReadMore from "../components/readmore";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, HeadFC } from "gatsby";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
const sanityClient = require("@sanity/client");
const client = sanityClient({
  projectId: "s4p7zji7",
  dataset: "production",
});

const builder = imageUrlBuilder(client);

function urlFor(source: any) {
  return builder.image(source);
}

const components = {
  types: {
    mainImage: ({ value }: { value: any }) => (
      <img src={urlFor(value.asset._ref).url()} className="" />
    ),
  },
};

const Post = ({ pageContext, data }: { pageContext: any; data: any }) => {
  const Post = data.sanityPost;
  var SlyS = [
    "Popular reads",
    "Don't stop reading",
    "can i intrest you in some more?",
    "people are reading this now",
    "hope you haven't missed this post",
    "oh boy, look at this",
    "what? i can't believe this!",
  ];
  var Sly = SlyS[Math.floor(Math.random() * SlyS.length)];
  return (
    <main className="text-richblack">
      <Layout></Layout>
      <div className="flex flex-col justify-center px-24 pt-48 pb-24 gap-12 after:content-[''] after:w-full after:h-1 after:bg-richblack after:block">
        <section className="flex flex-col gap-4">
          <h1 className="text-5xl uppercase font-black">{Post.title}</h1>
          <Link
            to={`/editor/${Post.authors[0].author.slug.current}`}
            className="flex flex-row gap-1 items-center"
          >
            <GatsbyImage
              image={Post.authors[0].author.image.asset.gatsbyImageData}
              alt={Post.authors[0].author.image.alt}
              className="rounded-full"
            ></GatsbyImage>
            <h2 className="text-bdazzledblue">
              {Post.authors[0].author.name}, {Post.publishedAt}. Last Updated at{" "}
              {Post._updatedAt}
            </h2>
          </Link>
          <Link
            to={`/tags/${Post.categories[0].slug.current}`}
            className="flex flex-row after:content-[''] after:w-[80%] after:h-1 after:bg-richblack after:block after:relative after:top-4 
            before:content-[''] before:w-[5%] before:h-1 before:bg-richblack before:block before:relative before:top-4"
          >
            <h3 className="text-2xl text-rosacorssa font-bold">
              | {Post.categories[0].title} |
            </h3>
          </Link>
        </section>
        <div className="w-[44rem]">
          <GatsbyImage
            image={Post.mainImage.asset.gatsbyImageData}
            alt={Post.mainImage.alt}
          ></GatsbyImage>
          <h3 className="text-bdazzledblue text-xs">
            {Post.mainImage.caption}
          </h3>
        </div>
        <div className="childP:text-2xl childP:text-justify childP:tracking-wider childP:font-normal childP:py-4 childP:leading-10 childImg:w-48 childImg:float-left 
        childImg:m-4 childP:ml-0 childH2:text-3xl childH2:font-medium childH2:underline">
          <PortableText
            value={Post._rawBody}
            components={components}
          ></PortableText>
        </div>
      </div>
      <section className="px-24 my-6 flex flex-col gap-4">
        <h1 className="uppercase text-5xl underline font-semibold">
          {Sly} - stay in the <span className="text-rosacorssa">know!</span>
        </h1>
        <ReadMore id={Post._id}></ReadMore>
      </section>
      <Footer></Footer>
    </main>
  );
};

export const Head: HeadFC = ({ data }: { data: any }) => (
  <title>{data.sanityPost.title}</title>
);

export const query = graphql`
  query ($id: String) {
    sanityPost(_id: { eq: $id }) {
      categories {
        slug {
          current
        }
        title
      }
      authors {
        author {
          image {
            alt
            asset {
              gatsbyImageData(width: 48)
            }
          }
          slug {
            current
          }
          name
        }
      }
      title
      mainImage {
        caption
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
        alt
      }
      publishedAt(formatString: "DD.MM.YYYY HH:mm")
      _rawBody
      _updatedAt(formatString: "DD.MM.YYYY HH:mm")
      _id
    }
  }
`;

export default Post;
